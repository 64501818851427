export function Team() {
  return (
    <div
      id="team"
      className="flex flex-col items-center lg:px-24 px-5 pt-12 lg:pt-20 pb-12 lg:pb-20"
    >
      <h1 className="md:text-7xl text-5xl font-semibold capitalize mb-16 font-serif">
        our team
      </h1>
      <div className="flex flex-wrap justify-center items-stretch h-full">
        {team.map((member) => {
          return (
            <div key={member.name} className="p-4 md:w-1/3 w-full  flex ">
              <div className="h-full w-full flex items-center justify-center flex-col bg-gray-100 bg-opacity-75 px-8 py-16 rounded-lg text-center">
                <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
                  {member.name}
                </h1>
                <p className="leading-relaxed text-gray-400">
                  {member.position}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

const team = [
  {
    name: "Yasser Al-Esawiy",
    position: "Managing Director",
    img: "",
  },
  {
    name: "Duaa Adil Al - Mareany",
    position: "Responsible for managing the company",
    img: "",
  },
  {
    name: "And an integrated group of elite lawyers and consultants",
    position: "",
    img: "",
  },
];
