import logo from "../assets/logoCroped.png";

export function HeroSection() {
  return (
    <section className="relative">
      <div
        id="banner-0"
        data-text-color="#111827"
        className="flex-shrink-0 flex relative items-center md:px-24 px-12"
        style={{ minHeight: "calc(100vh - 95px)", zIndex: 39 }}
      >
        <div className="absolute inset-0 pointer-events-none">
          <div
            className="absolute inset-0 z-10"
            style={{
              backgroundImage:
                "linear-gradient(rgb(223, 234, 255), rgb(22, 28, 40))",
              opacity: "0.5",
            }}
          />
          <div
            className="absolute inset-0 z-0"
            style={{
              backgroundImage:
                'url("https://images.unsplash.com/photo-1431540015161-0bf868a2d407?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyNjI5NjF8MHwxfHNlYXJjaHwxMnx8TGF3JTIwRmlybXxlbnwwfHx8fDE2OTYyMTYxMTN8MA&ixlib=rb-4.0.3&q=80&w=1080")',
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          />
        </div>
        <div className="flex md:flex-row flex-col items-center  z-10 container mx-auto pt-12 lg:pt-40 pb-12 lg:pb-40">
          <div className="max-w-3xl text-left ml-0 mr-auto opacity-100 translate-y-0">
            <h2
              className="md:text-7xl text-4xl font-bold mb-6 break-word text-left"
              style={{
                color: "rgb(17, 24, 39)",
                fontFamily: "serif",
              }}
            >
              Protect Your Rights With Al-Rashad
            </h2>
          </div>
          <div>
            <img src={logo} alt="" />
          </div>
        </div>
      </div>
    </section>
  );
}
