// import { useRef } from "react";

/* eslint-disable react/jsx-no-target-blank */
export function Location() {
  // const form = useRef<HTMLFormElement>({} as HTMLFormElement);

  // const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();

  //   sendForm(
  //     "service_uxe67fa",
  //     "template_8xlhtkv",
  //     form.current as unknown as HTMLFormElement,
  //     "user_5X6CajhDjKCwrtOu5WaYy"
  //   )
  //     .then(() => {
  //       window.alert("message sent");
  //     })
  //     .catch(() => {
  //       window.alert("unable to send");
  //     });
  // };
  return (
    <section
      id="contact"
      className=" body-font lg:px-24 px-5 py-12 flex-1 flex flex-col"
    >
      <h1 className="md:text-7xl text-5xl font-semibold font-serif text-center">
        Contact Us
      </h1>
      <div className="container px-5 mx-auto flex sm:flex-nowrap flex-wrap">
        <div className="w-full flex flex-wrap md:py-12 mt-8 md:mt-0">
          <div className="lg:w-1/2 w-full mb-8 lg:mb-0">
            <h1 className="text-gray-900 lg:text-4xl text-2xl mb-4 font-medium">
              Contact Info
            </h1>
            <div className="">
              <span className=" text-lg">Phone:</span>
              <a
                href="tel:+9647802000360"
                className="text-lg ml-2"
                target="_blank"
              >
                +964-780-2000-360
              </a>
            </div>
            <div className="flex ">
              <span className=" text-lg">Whatsapp:</span>
              <a
                href="https://wa.me/009647802000360"
                className="text-lg ml-2"
                target="_blank"
              >
                +964-780-2000-360
              </a>
            </div>
            <div className="">
              <span className=" text-lg">Email:</span>
              <a
                href="mailto:yasser.ali@alrashad-company.com"
                className=" text-lg ml-2"
              >
                yasser.ali@alrashad-company.com
              </a>
            </div>
          </div>
          <div className=" lg:w-1/2 w-full">
            <h1 className="text-gray-900 lg:text-4xl text-2xl mb-4 font-medium">
              Addresses
            </h1>
            <ul className="list-disc ml-10">
              <li className=" text-lg">Baghdad / Al-Harithiya</li>
              <li className=" text-lg">Basra / Minawi Pasha</li>
              <li className=" text-lg">Erbil / 100m Street</li>
            </ul>
          </div>
        </div>
        {/* <div className="lg:w-1/2 w-full bg-white flex flex-col md:ml-auto w-full md:py-12 mt-8 md:mt-0">
          <h2 className="text-gray-900 lg:text-4xl text-2xl mb-1 font-medium title-font">
            Send us an E-mail
          </h2>
          <form onSubmit={onSubmit} ref={form}>
            <div className="relative mb-4">
              <label htmlFor="name" className="leading-7 text-sm text-gray-600">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="email"
                className="leading-7 text-sm text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
              />
            </div>
            <div className="relative mb-4">
              <label
                htmlFor="message"
                className="leading-7 text-sm text-gray-600"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                className="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              type="submit"
              className="text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
            >
              Send
            </button>
          </form>
        </div> */}
      </div>
    </section>
  );
}
