/* eslint-disable jsx-a11y/alt-text */
import primeinfra from "../assets/primeinfra.png";
import goldHeart from "../assets/goldheart.png";
import ictsi from "../assets/ictsi.jpg";
import menatech from "../assets/menatech.jpeg";
import { Chip } from "./ServicesSection";

export function NewClients() {
  return (
    <section
      id="clients"
      className="relative lg:px-24 px-5"
      style={{ backgroundColor: "rgb(236, 236, 236)" }}
    >
      <div
        id="gallery-4"
        className="flex flex-none flex-shrink-0 relative items-center"
        style={{ minHeight: "calc(134px - 0px)", zIndex: 35 }}
      >
        <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
          <div style={{}} className="opacity-100 translate-y-0">
            <div className="">
              <h2
                className="md:text-7xl text-5xl font-semibold mb-6 text-center"
                style={{
                  color: "rgb(17, 24, 39)",
                  fontFamily: "serif",
                }}
              >
                Our Clients
              </h2>
            </div>
            <div className="">
              <div className="flex justify-between items-center flex-wrap">
                <div className="md:w-1/6 px-4 w-1/2 md:h-1/6 h-1/2">
                  <a
                    className=""
                    href="https://primeinfra.ph/portfolio/prime-power-energy-solutions/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-full h-full"
                      src={primeinfra}
                      alt="primeinfra"
                    />
                  </a>
                </div>
                <div className="md:w-1/6 px-4 w-1/2 md:h-1/6 h-1/2">
                  <a
                    className=""
                    href="https://goldheart-int.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-full h-full"
                      src={goldHeart}
                      alt="goldHeart"
                    />
                  </a>
                </div>
                <div className="md:w-1/6 px-4 w-1/2 md:h-1/6 h-1/2">
                  <a
                    className=""
                    href="https://www.ictsiiraq.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img className="w-full h-full" src={ictsi} alt="ictsi" />
                  </a>
                </div>
                <div className="md:w-1/6 px-4 w-1/2 md:h-1/6 h-1/2">
                  <a
                    className=""
                    href="https://www.linkedin.com/company/menatech-ltd/about/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-full h-full"
                      src={menatech}
                      alt="menatech"
                    />
                  </a>
                </div>
              </div>
              <div className="mt-6 grid grid-cols-1 lg:grid-cols-3 gap-6 md:gap-10 mb-6 md:mb-10">
                {clients.map((client) => (
                  <Chip key={client} text={client} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

const clients = [
  // "GOLD HEART COMPANY",
  // "MENATIC COMPANY",
  // "ICTSI COMPANY",
  "AL-DOGHA COMPANY",
  "RAYAN AL-RAFIDAIN COMPANY",
  "PEARSEAN COMPANY",
  "AL - ZUMORAD COMPANY",
  "AL - TAKHASUSIA COMPANY",
  "AMAQ AL -MOHEAT COMPANY",
  "ALMAA AL AZRAQ COMPANY",
  "EBDAA AL -KHALID COMPANY",
  "ROAA MILAN COMPANY",
];
