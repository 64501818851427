export function ServicesSection() {
  return (
    <section id="services" className="relative lg:px-24 px-5">
      <div
        id="list-2"
        className="relative flex flex-none flex-shrink-0 items-center"
        style={{ minHeight: "calc(134px - 0px)", zIndex: 37 }}
      >
        <div className="absolute inset-0 pointer-events-none">
          <div
            className="absolute inset-0 z-10"
            style={{ backgroundColor: "rgb(255, 255, 255)" }}
          />
        </div>
        <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
          <div>
            <h2
              className="md:text-7xl text-5xl font-semibold mb-6 text-left opacity-100 translate-y-0"
              style={{
                color: "rgb(17, 24, 39)",
                fontFamily: "serif",
              }}
            >
              Services
            </h2>
            <div
              className=" w-full flex-wrap gap-5"
              style={{
                flex: "0 0 calc(33.3333% - 26.6667px)",
                flexDirection: "row",
                display: "flex",
                // gap: 40,
              }}
            >
              {services.map((service) => (
                <Chip key={service} text={service} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export const Chip = ({ text }: { text: string }) => {
  return (
    <div className="flex rounded-lg justify-center items-center px-3 py-4 text-white hover:text-slate-800 bg-slate-800 hover:bg-slate-400">
      <p
        className="md:text-2xl text-lg capitalize "
        style={{
          fontFamily: "serif",
          fontWeight: 400,
          textAlign: "center",
        }}
      >
        {text}
      </p>
    </div>
  );
};
const services = [
  "Litigation",
  "Customs output customs clearance",
  "Commercial Banking Transactions",
  "Taxes",
  "Registration of companies agencies and trademarks",
  "Conflict Resolution",
  "Real Estate",
  "contracts",
  "labor law and social security",
];
