import logo from "../assets/logoCroped.png";

export function Footer() {
  return (     
    <footer className="flex flex-1 px-24 bg-slate-400">
      <div id="website-footer" className="relative flex-1 z-10">
        <div className="relative z-10 container mx-auto pt-12 lg:pt-14 xl:pt-20 pb-12 lg:pb-14 xl:pb-20">
          <div className="flex flex-col lg:flex-row items-start lg:justify-between gap-12">
            <div className="flex flex-col gap-6 justify-center lg:justify-start w-full lg:max-w-[30vw]">
              <div className="max-w-full justify-center lg:justify-start overflow-hidden grid">
                <img src={logo} style={{ maxWidth: 150 }} alt="" />
              </div>
            </div>
            <div className="flex flex-col gap-12 lg:gap-6 justify-self-stretch h-full w-full  lg:w-auto">
              <div className="flex flex-col lg:flex-row gap-12 lg:items-center justify-center lg:justify-end">
                <ul className="flex justify-center w-full items-center gap-x-4 gap-y-2 flex-wrap">
                  <li>
                    <a
                      target="_blank"
                      href="https://facebook.com/profile.php?id=100094712741741"
                      rel="noreferrer"
                    >
                      <div className="flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          width={60}
                          height={60}
                          // className="w-8 h-8"
                          style={{ color: "rgb(255, 255, 255)" }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M10.4375 21.8784C5.65941 21.1274 2 16.9877 2 12 2 6.48086 6.48086 2 12 2c5.5191 0 10 4.48086 10 10 0 4.9877-3.6594 9.1274-8.4375 9.8784v-6.9878h2.3301L16.3359 12h-2.7734v-1.8758c0-.79084.3874-1.5617 1.6296-1.5617h1.261V6.10156s-1.1443-.19531-2.2385-.19531c-2.2842 0-3.7771 1.38438-3.7771 3.89063V12H7.89844v2.8906h2.53906v6.9878Z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/company/rashad-for-legal-services/"
                      rel="noreferrer"
                    >
                      <div className="flex justify-center items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="60"
                          height="60"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                          style={{ color: "#fff" }}
                        >
                          <path d="M19 0h-14c-2.761 0-5 2.239-5 5v14c0 2.761 2.239 5 5 5h14c2.762 0 5-2.239 5-5v-14c0-2.761-2.238-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.268c-.966 0-1.75-.79-1.75-1.764s.784-1.764 1.75-1.764 1.75.79 1.75 1.764-.783 1.764-1.75 1.764zm13.5 12.268h-3v-5.604c0-3.368-4-3.113-4 0v5.604h-3v-11h3v1.765c1.396-2.586 7-2.777 7 2.476v6.759z" />
                        </svg>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
