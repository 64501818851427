/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import logo from "../assets/logoCroped.png";
function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 bg-slate-200 ${
        isSticky ? "shadow-lg" : ""
      }`}
    >
      <nav className="container mx-auto lg:px-24 px-12 py-4">
        <div className="flex justify-between items-center">
          <div className="text-white font-bold text-xl">
            <img src={logo} width={65} alt="" />
          </div>
          <div className="md:hidden block">
            <button
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
              className="text-white hover:text-gray-300"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="#000"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div className="md:flex hidden space-x-4">
            <a href="/" className=" hover:text-gray-600">
              Home
            </a>
            <a href="#about" className=" hover:text-gray-600">
              About
            </a>
            <a href="#services" className=" hover:text-gray-600">
              Services
            </a>
            <a href="#clients" className=" hover:text-gray-600">
              Clients
            </a>
            <a href="#team" className=" hover:text-gray-600">
              Team
            </a>
            <a href="#contact" className=" hover:text-gray-600">
              Contact
            </a>
          </div>
        </div>
      </nav>
      <div className={`md:hidden ${isMobileMenuOpen ? "" : "hidden"}`}>
        <div className="bg-slate-200 p-4">
          <a href="#" className="block py-2 hover:text-gray-600">
            Home
          </a>
          <a href="#about" className="block py-2 hover:text-gray-600">
            About
          </a>
          <a href="#services" className="block py-2 hover:text-gray-600">
            Services
          </a>
          <a href="#clients" className="block py-2 hover:text-gray-600">
            Clients
          </a>
          <a href="#team" className="block py-2 hover:text-gray-600">
            Team
          </a>
          <a href="#contact" className="block py-2 hover:text-gray-600">
            Contact
          </a>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
