/* eslint-disable jsx-a11y/alt-text */
import "./App.css";
import Navbar from "./components/Navbar";
import { HeroSection } from "./components/HeroSection";
import { BriefSection } from "./components/BriefSection";
import { ServicesSection } from "./components/ServicesSection";
import { AboutUs } from "./components/AboutSection";
import { NewClients } from "./components/ClientsSection";
import { Location } from "./components/Location";
import { Footer } from "./components/Footer";
import { Team } from "./components/TeamSection";

function App() {
  return (
    <div className="flex flex-col flex-wrap">
      <Navbar />
      <HeroSection />
      <BriefSection />
      <ServicesSection />
      <AboutUs />
      <NewClients />
      <Team />
      <Location />
      <Footer />
    </div>
  );
}

export default App;
