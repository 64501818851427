export function BriefSection() {
  return (
    <section
      className="relative lg:px-24 px-5"
      style={{ backgroundColor: "rgb(236, 236, 236)" }}
    >
      <div
        id="text-1"
        className="flex flex-none flex-shrink-0 relative items-center"
        style={{ minHeight: "calc(134px - 0px)", zIndex: 38 }}
      >
        <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
          <div
            className="rich-text-block max-w-5xl text-center mx-auto opacity-100 translate-y-0"
            style={{ color: "rgb(17, 24, 39)" }}
          >
            <p className="text-lg" style={{ whiteSpace: "pre-line" }}>
              Our Company Was Established In Baghdad After Obtaining All
              Licenses And Official Approvals From Lawyers Who Have Dedicated
              Themselves To Provide The Best Legal Service In Iraq And The Best
              Solutions To Its Clients.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
