export function AboutUs() {
  return (
    <section
      id="about"
      className="relative lg:px-24 px-5"
      style={{ backgroundColor: "rgb(223, 234, 255)" }}
    >
      <div
        id="hero-3"
        className="flex flex-none flex-shrink-0 relative items-center "
        style={{ minHeight: "calc(134px - 0px)", zIndex: 36 }}
      >
        <div className="relative z-10 container mx-auto pt-12 lg:pt-20 pb-12 lg:pb-20">
          <div
            className="flex flex-col lg:flex-row w-full gap-10 lg:gap-20 items-center opacity-100 translate-y-0"
            style={{}}
          >
            <div className="flex-1 flex flex-col items-center lg:items-start">
              <div
                className="rich-text-block"
                style={{ color: "rgb(17, 24, 39)" }}
              >
                <h3
                  className="md:text-7xl text-5xl font-semibold mb-6"
                  style={{ whiteSpace: "pre-line", fontFamily: "serif" }}
                >
                  About us
                </h3>
                <p className="text-lg">
                  Welcome to AL-Rashad, a reputable law firm based in Baghdad,
                  Iraq. With our extensive legal expertise, we are committed to
                  providing exceptional legal services to our clients.
                </p>
                <p className="text-lg">
                  Our team of highly skilled and experienced lawyers is
                  dedicated to delivering personalized solutions tailored to
                  meet the unique needs of each client. We strive to achieve the
                  best possible outcomes by combining our in-depth knowledge of
                  Iraqi law with a client-centric approach. Trust AL-Rashad to
                  handle your legal matters with professionalism, integrity, and
                  utmost confidentiality.
                </p>
              </div>
            </div>
            <div className="flex-1 flex w-full justify-center lg:justify-start">
              <div className="flex-shrink-0 relative w-full mx-auto aspect-w-16 aspect-h-9">
                <img
                  alt="We provide high quality services"
                  src="https://images.unsplash.com/photo-1619771766980-368d32e44b82?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=M3wyNjI5NjF8MHwxfHNlYXJjaHwxOXx8TGF3JTIwRmlybXxlbnwwfHx8fDE2OTYyMTYxMTN8MA&ixlib=rb-4.0.3&q=80&w=1080"
                  className="rounded-2xl md:rounded-3xl lg:rounded-4xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
